<template>
  <div>
    <portal to="v-main">
      <s-edit-form
        v-model="showEditDialog"
        v-if="showEditDialog"
        :m="configH.m"
        :configForm="{ head: configH.head, title: configH.title, api: configH.api }"
        :id="configH.id"
        :opt="configH.opt"
        :initData="configH.initData || {}"
        :params="configH"
      />
    </portal>
    <v-card-text :style="`height: ${height}px; overflow: auto`" class="pa-0">
      <v-fab-transition v-if="canAdd">
        <v-btn fab dark color="green" absolute bottom small style="bottom: 25px" left class="v-btn--example" @click="add">
          <v-icon>mdi-plus</v-icon>
        </v-btn> </v-fab-transition
      ><a-table-f-api
        ref="table"
        :api="api"
        :model="m.list"
        :searchable="false"
        :defaults="{
          filters,
          sort: { key: 'id', order: 'DESC' },
          paramName: 'tabShopLink',
        }"
        @click="onClickRow($event)"
      >
      </a-table-f-api>
    </v-card-text>
  </div>
</template>

<script>
import { getAccess, popupMenu } from "@/components/mixings";
export default {
  mixins: [getAccess, popupMenu],
  components: {},
  props: {
    id: Number,
    data: Object,
    height: { type: Number, default: 300 },
  },
  data() {
    return {
      showEditDialog: false,
      configH: null,
      m: this.$store.getters["config/get"].models.shopLink,
    };
  },
  created() {},
  watch: {
    showReportDialog() {
      if (!this.showReportDialog) {
        this.$refs.tableReports.updateData();
      }
    },
  },
  computed: {
    canAdd() {
      return true;
    },
    filters() {
      let f = { vendor_id: this.id };
      return f;
    },
    api() {
      return this.configRole.api;
    },
    configRole() {
      let config;
      if (this.$root.profile.role == 1000) config = this.m.config.role_1000 || {};
      else config = this.m.config.default || {};
      return config;
    },
  },
  watch: {
    showEditDialog() {
      if (!this.showEditDialog) {
        this.$refs.table.updateData();
      }
    },
  },
  methods: {
    add() {
      const config = {};
      this.configH = null;
      config.head = this.configRole;
      config.title = this.configRole.title;
      config.api = this.api;
      config.id = null;
      let opt = [];
      if (!this.getAccess("vendor.shopLink.create")) opt.push("readonly");
      if (this.getAccess("vendor.shopLink.delete")) opt.push("delete");
      config.opt = opt.join(",");
      config.initData = { vendor_id: this.id, status: 1 };
      config.m = this.m;
      this.configH = config;
      this.showEditDialog = true;
    },
    onClickRow(e) {
      const config = {};
      this.configH = null;
      config.head = this.configRole;
      config.title = this.configRole.title;
      config.api = this.api;
      config.id = e.row.id;
      config.fieldsRO = "shop_id";
      let opt = [];
      if (!this.getAccess("vendor.shopLink.create")) opt.push("readonly");
      if (this.getAccess("vendor.shopLink.delete")) opt.push("delete");
      config.opt = opt.join(",");
      config.initData = { vendor_id: this.id, status: 1 };
      config.m = this.m;
      this.configH = config;
      this.showEditDialog = true;
    },
  },
};
</script>

<style lang="sass"></style>
